<template>
  <div class="page">
    <Header :loginState="loginState" />
    <div class="account-page">
      <left-menu />
      <div class="account-center">
        <div class="center-box account-information">
          <account-nav />
          <div class="title-box">           
            <div class="cfx"></div>
            <p>{{$t('accountInfor.bindPhoneTitle5')}}</p>
          </div>
          <!-- 绑定手机号码 -->
          <div v-if="this.findUserInfo.mobileIsVer == 0" class="identity-box">
            <p class="tips-p">{{$t('accountInfor.bindPhoneTitle1')}}</p>
            <div class="from-box">
              <mobile-input :countryList="countryList" :errShowList="errShowList" @mobileBlur="inputBlur"  @country="selectCountry" @inputVal="inputPhoneVal"/>
              <code-input :verCodeData="verCodeData" :errCodeList="errCodeList" @codeBlur="phoneCodeBlur()" @getVerCodeEmit="getCode(1)"  @onPhoneCode="codeVal"/>
              <input @click="bindMobile" class="input-btn" type="button" :value="$t('accountInfor.bind')" />
            </div>
          </div>

          <!-- 修改手机号码 -->
          <div v-if="this.findUserInfo.mobileIsVer == 1" class="identity-box mailbox">
            <p class="tips-p">{{$t('accountInfor.bindPhoneTitle2')}}</p>
            <div class="from-box">
              <mobile-input :countryList="countryList" :errShowList="errShowList" @mobileBlur="inputBlur" @country="selectCountry" @inputVal="inputPhoneVal" :mobileValue="modelMobile" />
              <code-input :verCodeData="verCodeData" :errCodeList="errCodeList" @codeBlur="phoneCodeBlur()" @getVerCodeEmit="getCode(2)"  @onPhoneCode="codeVal"/>
              <input @click="modifyMobile" class="input-btn" type="button" :value="$t('perfectData.modify')" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 提示框组件 -->
    <Dialog ref="myConfirm"  :isShowConfirm="isShowConfirm" @userBehavior="userBehaviorFun" />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import LeftMenu from '@/components/LeftMenu.vue';
import AccountNav from '@/components/AccountNav.vue';
import MobileInput from '@/components/MobileInput.vue';
import CodeInput from '@/components/CodeInput.vue';
import Dialog from '@/components/Dialog.vue';
import account from '@/api/account.js';
import { getCookie } from '@/util/cookie';
import {HEADER_USER_TOKEN} from "@/util/const";
export default {
  name: "PhoneNumber",
  components: {
    Header,
    LeftMenu,
    AccountNav,
    MobileInput,
    CodeInput,
    Dialog
  },
  data() {
    return {
      domainUrl:'',
      loginState:2,
      isShowConfirm:false,
      findUserInfo:[],
      modelMobile:'',
      country_name:'',
      verCodeData: {
        isVerCode: '0',
        showTime:60,
        interval:'',
      }, 
      countryList: {
        countrys: [],
        countryActive:'',
      },
      countryCurrent:[],
      countryList1: {
        countrys: [],
        countryActive:'',
      },
      countryCurrent1:[],
      phone:'',
      phoneCode:'',
      errShowList: {
        errShow:false,
        errContent:'',
      },
      errCodeList: {
        errShow:false,
        errContent:'',
      }
    }
  },
  created() {
    this.domainUrl = window.location.host;
    //根据当前国家ip地址，获取当前国家id
    account.getCurrentCountryByIp().then((res)=>{
     this.country_name = res.data.value.country_name;
     console.log(this.country_name+"ip");
    }).catch(err=>{
      console.log(err);
    })
    // 查询用户基本资料
    const userToken = getCookie(HEADER_USER_TOKEN);
    console.log(userToken);
    if(!userToken){
      this.$router.push({ path: '/login' })
    }else{
      account.findUserInfo().then((res)=>{
        if(res.data.success == 1){
              this.findUserInfo = res.data.value
              if(res.data.value.countryId == 'Other'){
                  // 获取手机号注册的国家下拉列表数据
                  account.getSupportCountry({"type":"mobile"}).then((res)=>{
                  this.countryList.countrys = res.data.value
                  if(this.country_name == 'Vietnam' || this.country_name == 'Indonesia' || this.country_name == 'Taiwan' || this.country_name == 'Thailand'){
                    this.countryList.countryActive = this.country_name;
                  }else{
                    this.countryList.countryActive = res.data.value[0].countryId //默认选中第一个国家
                  }  
                  let countryCurrent = this.countryList.countrys.filter(it => it.countryId == this.countryList.countryActive);//帅选选中的国家数据
                  this.countryCurrent = countryCurrent;                            
                  }).catch(err=>{
                    console.log(err.data.message);
                  })
              }else{
                this.getSupportCountryList(res.data.value.countryId)                               
              }             
              let reg=RegExp(/-/)
              if(reg.test(res.data.value.mobile)){
                let index = res.data.value.mobile.indexOf("-");
                this.modelMobile = res.data.value.mobile.substr(index + 1,res.data.value.mobile.length);
              }
          }else{
              console.log(res.data.message);
          }
      }).catch(err=>{
        console.log(err.message);
      })
    }  
  },
  methods: {

    getSupportCountryList(val){
      // 绑定手机号获取国家下拉列表数据
      account.getSupportCountry({"type":"mobile"}).then((res)=>{
      // 绑定手机号获取国家下拉列表数据
      this.countryList.countrys = res.data.value
      this.countryList.countryActive = val //默认选中第一个国家
      let countryCurrent = this.countryList.countrys.filter(it => it.countryId == this.countryList.countryActive);//帅选选中的国家数据
      this.countryCurrent = countryCurrent;
      }).catch(err=>{
        console.log(err.data.message);
      })
    },
    // 获取选中的国家及国家区码
    selectCountry(val){
      let countryCurrent = this.countryList.countrys.filter(it => it.countryId == val);//帅选选中的国家数据
      this.countryCurrent = countryCurrent;
      console.info("countryCurrent",this.countryCurrent[0].countryCallingCode);
    },

    // 获取输入的手机号
    inputPhoneVal(val){
      this.phone = val
      console.log(this.phone);
    },

    // 获取输入的验证码
    codeVal(val){
      this.phoneCode = val
      console.log(this.phoneCode);
    },

    // 鼠标离开输入框校验不能为空
    inputBlur(val){
      if(val == "mobile"){
        const regPhone = eval(this.countryCurrent[0].frontendRegex);
        console.log("frontendRegex"+regPhone);
        if(!this.phone){
          this.errShowList.errShow = true;
          this.errShowList.errContent = this.$i18n.t('loginRegister.checkPrompt25'); 
        }else{
          if(!regPhone.test(this.phone)){
            this.errShowList.errShow = true;
            this.errShowList.errContent = this.$i18n.t('loginRegister.checkPrompt5'); 
          }else{
            this.errShowList.errShow = false;
          }
        }             
      }
    },  
    phoneCodeBlur(){
      if(!this.phoneCode){
        this.errCodeList.errShow = true;
        this.errCodeList.errContent = this.$i18n.t('loginRegister.checkPrompt6');
      }else{
        this.errCodeList.errShow = false;
      }
    },
    // 获取手机验证码
    getCode(num){
      const regPhone = eval(this.countryCurrent[0].frontendRegex);
      console.log("frontendRegex"+regPhone);
        if(!this.phone){
          this.errShowList.errShow = true;
          if(num == 1){
            this.errShowList.errContent = this.$i18n.t('loginRegister.checkPrompt4');
          }else{
            this.errShowList.errContent = this.$i18n.t('loginRegister.checkPrompt25');
          }        
          return;  
        }
        if(!regPhone.test(this.phone)){
          this.errShowList.errShow = true;
          this.errShowList.errContent = this.$i18n.t('loginRegister.checkPrompt5'); 
          return;
        }else{
          this.errShowList.errShow = false;
        }
      
        let data = {
          countryCode: this.countryCurrent[0].countryCallingCode,
          countryId: this.countryCurrent[0].countryId,
          phone: this.phone,
          type: 'mobile',
          domainUrl:this.domainUrl
        }
        account.phoneCode(data).then((res)=>{
          if(res.data.success == 1){
            let _this = this;
            _this.verCodeData.isVerCode = 1;
            _this.verCodeData.interval = setInterval(function(){
              _this.verCodeData.showTime--;
              if(_this.verCodeData.showTime < 1){
                clearInterval(_this.verCodeData.interval);
                _this.verCodeData.interval ="";
                _this.verCodeData.showTime = 60;
                _this.verCodeData.isVerCode = 0;
              }
            },1000);
          }else{
            this.errShowList.errShow = true;
            this.errShowList.errContent = res.data.message;
          }
          
        }).catch(err=>{
          console.log(err.message);
        })
        this.errShowList.errShow = false;
        
    },

    // 点击修改手机号码
    modifyMobile(){
      const regPhone = eval(this.countryCurrent[0].frontendRegex);
      console.log("frontendRegex"+regPhone);
      if(!this.phone){
        this.errShowList.errShow = true;
        this.errShowList.errContent = this.$i18n.t('loginRegister.checkPrompt25'); 
        return;  
      }
      if(!regPhone.test(this.phone)){
        this.errShowList.errShow = true;
        this.errShowList.errContent = this.$i18n.t('loginRegister.checkPrompt5'); 
        return;
      }else{
        this.errShowList.errShow = false;
      }
      if(!this.phoneCode){
        this.errShowList.errShow = false;
        this.errCodeList.errShow = true;
        this.errCodeList.errContent = this.$i18n.t('loginRegister.checkPrompt6');
        return;
      }else{
        this.errCodeList.errShow = false;
      }

      let data = {
        countryCode: this.countryCurrent[0].countryCallingCode,
        // countryId: this.countryCurrent[0].countryId,
        mobile: this.phone,
        verCode: this.phoneCode,
        domainUrl:this.domainUrl,
      }
      account.updateMobile(data).then((res)=>{
          if(res.data.success == 1){
            const popTitle = this.$i18n.t('accountInfor.bindPhoneTitle4');//多语言传入的文字
            const cancel = this.$i18n.t('perfectData.cancel');
            const confirm = this.$i18n.t('perfectData.continue');
            const title = this.$i18n.t('accountCenter.reminder');
            this.isShowConfirm = true
            this.$refs.myConfirm.show(popTitle, {
                type: 'confirm',
                cancelText:cancel,
                confirmText: confirm,
                titleText: title,
                data: '我是外界传进来的数据'
            })
          }else{
            this.errCodeList.errShow = true;
            this.errCodeList.errContent = res.data.message;
          }
      }).catch(err=>{
        this.errCodeList.errShow = true;
        this.errCodeList.errContent = err.message;
        console.log(err.message);
      })
    },

    // 点击绑定手机号码
    bindMobile(){
      const regPhone = eval(this.countryCurrent[0].frontendRegex);
      console.log("frontendRegex"+regPhone);
      if(!this.phone){
        this.errShowList.errShow = true;
        this.errShowList.errContent = this.$i18n.t('loginRegister.checkPrompt4'); 
        return;  
      }
      if(!regPhone.test(this.phone)){
        this.errShowList.errShow = true;
        this.errShowList.errContent = this.$i18n.t('loginRegister.checkPrompt5'); 
        return;
      }else{
        this.errShowList.errShow = false;
      }
      if(!this.phoneCode){
        this.errShowList.errShow = false;
        this.errCodeList.errShow = true;
        this.errCodeList.errContent = this.$i18n.t('loginRegister.checkPrompt6');
        return;
      }else{
        this.errCodeList.errShow = false;
      }

      let data = {
        countryCode: this.countryCurrent[0].countryCallingCode,
        // countryId: this.countryCurrent[0].countryId,
        mobile: this.phone,
        verCode: this.phoneCode,
        domainUrl:this.domainUrl
      }
      account.updateMobile(data).then((res)=>{
          if(res.data.success == 1){
            const popTitle = this.$i18n.t('accountInfor.bindPhoneTitle3');//多语言传入的文字
            const cancel = this.$i18n.t('perfectData.cancel');
            const confirm = this.$i18n.t('perfectData.continue');
            const title = this.$i18n.t('accountCenter.reminder');
            this.isShowConfirm = true
            this.$refs.myConfirm.show(popTitle, {
                type: 'confirm',
                cancelText:cancel,
                confirmText: confirm,
                titleText: title,
                data: '我是外界传进来的数据'
            })
          }else{
            this.errCodeList.errShow = true;
            this.errCodeList.errContent = res.data.message;
          }
      }).catch(err=>{
        this.errCodeList.errShow = true;
        this.errCodeList.errContent = err.message;
        console.log(err.message);
      })
    },

    // 提示框组件传递取消和确定事件,根据对应的事件类型跳转或关闭提示框
    userBehaviorFun(type){
      console.log(type);
      const that = this;
      if(type === "clickCancel"){
        that.$router.push("./");//去账户首页
        this.isShowConfirm = false    
      }else if(type === "clickConfirm"){
        // that.$router.push("./");
        this.isShowConfirm = false    
      }          
    }, 
    
  },
};
</script>
<style>
@import url("../assets/css/public.css");
</style>
